export const data = [
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 3 gorgées' },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 5 gorgées' },
    { title: 'Mets deux joueurs en couple (je bois tu bois)', option: '', style: { backgroundColor: '#FF47FC' } },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 3 gorgées' },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 5 gorgées' },
    { title: 'Donne 1 shot', option: '', style: { backgroundColor: '#F7D002' } },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 3 gorgées' },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { title: 'Joker', option: '', style: { backgroundColor: '#F7D002' } },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 3 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 5 gorgées' },
    { option: '', style: { backgroundColor: '#C84630' }, title: 'Prends 5 gorgées' },
    { option: '', style: { backgroundColor: '#018E42' }, title: 'Distribue 3 gorgées' },
    { title: 'Prends un shot', option: '', style: { backgroundColor: 'black', textColor: 'white' } },
  
  ]