import React from 'react'
import "./index.css"
import { questions } from '../../../../QuestionsStore'

export default function index({ infos }) {

    let qs = [];
    for(let index of infos.question) {
        qs.push(questions[index]);
    }

    return (
        <div className="question">

            <p className="question_intitule"> <span> {infos.player.name} </span> réponds la <span>vérité</span>  pour 2 des 3 questions et <span> bluffes </span> pour l'une d'entre elles... </p>
            {qs.map(q => <p className="question_elem"> {q} </p>)}

        </div>
    )
}
