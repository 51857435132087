// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBlxdwGesozO2EElnMDPY2_NDKncCAM0SY",
  authDomain: "hammered-c8ace.firebaseapp.com",
  projectId: "hammered-c8ace",
  storageBucket: "hammered-c8ace.appspot.com",
  messagingSenderId: "251292449803",
  appId: "1:251292449803:web:f00bec99df87309bd59b0e",
  databaseURL: "https://hammered-c8ace-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };