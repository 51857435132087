import React, { useEffect, useState } from 'react'
import "./index.css";

const lobbyData = {
  "id": 1,
  "name": "Lobby 1",
  "players": [
    {
      "id": 1,
      "name": "Player 1",
    },
    {
      "id": 2,
      "name": "Player 2",
    },
    {
      "id": 3,
      "name": "Player 3",
    },
  ],
  "owner": 1,
}

export default function Lobby({ data, lobbyId, owner, pushName, startGame }) {

  const [name, setName] = useState("");
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    console.log(isValid, lobbyId);
    if (!isValid && lobbyId) {
      //take all the characters before the - in a string
      const infosP = window.localStorage.getItem('playerInfo');
      if (infosP) {
        const lobName = infosP.split('-')[0];
        if (lobbyId == lobName) {
          setIsValid(true);
        }
      }
    }
  }, [lobbyId])

  const validName = () => {

    if (name.length < 2) {
      return;
    }

    pushName(name);
    setIsValid(true);

    return false;
  }

  return (
    <div className="lobby">
      {!isValid || !data?.players ? <><p className="lobby_join"> Rentrez votre nom: </p>
        <input className="lobby_input" type="text" value={name} onChange={(e) => setName(e.target.value)} />
        <div className="base_button" onClick={validName}>
          Valider
        </div> </> :
        <div className="lobby_container">
          <p className="lobby_rules"> Liste des joueurs </p>
          <div className="lobby_players">
          {data.players.map((player) => {
            return (
              <div key={player.id} className="lobby_player">
                <img className="lobby_player_img" alt="face" src={`https://avatars.dicebear.com/api/big-smile/${player.id}.svg`} />
                <p className="lobby_player_name">{player.name}</p>
                <div className="lobby_player_ready" style={{ backgroundColor: "green" }}> Prêt </div>
              </div>
            )
          })}
          </div>
          <p className="lobby_rules" style={{ marginTop: 10, marginBottom: 4 }}> A lire avant de commencer </p>
          <p className="lobby_rule"> Seul le créateur de la partie peut passer les questions. </p>
          <p className="lobby_rule"> La plupart des questions se répondent à voix haute. </p>
          <p className="lobby_rule"> Amusez-vous, ne vous forcez pas et buvez avec modération ! </p>

          <div className="base_button lobby_button lobby_linkContainer" onClick={() => navigator.clipboard.writeText(window.location.href)}>
            <p className="lobby_linkText"> Partagez le lien  </p>
            <img className="lobby_linkImg" alt="link" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/56/Chain_link_icon_slanted.png/800px-Chain_link_icon_slanted.png" />
          </div>
          {owner && <div className="base_button" style={{ marginTop: 20 }} onClick={startGame}>
            Lancez la partie
          </div>}
        </div>}
    </div>
  )
}
