import React, { useState } from 'react'
import Questions3 from "./3Questions";
import "./index.css";
import Roulette from "./Roulette"
import VotePourTlm from "./VotePourTlm"
import Classique from "./1Question2Gages";

export default function InGame({ nextQ, lobbyData, owner }) {
  
  if(!lobbyData?.questions) return null;
  let type = lobbyData?.questions[lobbyData?.questionIndex].type;

  return (
    <div className="ingame">
      {type === 'classique' ? <Classique infos={lobbyData.questions[lobbyData.questionIndex]} /> :
      type === 'multiples' ? <Questions3 infos={lobbyData.questions[lobbyData.questionIndex]} />
      : type === 'qui' ? <VotePourTlm lobbyData={lobbyData} users={lobbyData.players} infos={lobbyData.questions[lobbyData.questionIndex]} /> : <Roulette lobbyData={lobbyData} infos={lobbyData.questions[lobbyData.questionIndex]}/>}
      {owner && <div className="base_button ingame_button"onClick={nextQ}>
            Passer la question
        </div>}
    </div>
  )
}
