import React from 'react'
import "./index.css";
import { questions } from '../../../../QuestionsStore'
import { gages } from '../../../../Gages';

export default function index({ infos }) {
  return (
    <>
      <p className="question_intitule"> A toi de jouer <span style={{ fontWeight: 'bold' }}> {infos.player.name}</span>, tu as le choix : </p>
      <p className="question_elem"> {questions[infos.question[0]]} </p>
      <p className="question_choix"> ou </p>
      <p className="question_elem"> {infos.question[1]} </p>
    </>
  )
}
