import React, { useState } from 'react'
import "./index.css"
import { Link } from "react-router-dom";
import hammered from "../../hammered.png"
//create random six digits number


export default function Accueil() {

    const [join, setJoin] = useState(false);
    const [text, setText] = useState("");

    return (
        <div className="accueil_container">
            <img className="accueil_img" alt="img" src={hammered} />
            {join ?
                <>
                    <p className="accueil_join"> Entrez le code de la partie: </p>
                    <input placeholder="238432" type="text" className="accueil_input" value={text} onChange={(e) => setText(e.target.value)} />
                    <Link to={`/game/${text}`}>
                        <div className="base_button">
                            Rejoindre cette partie
                        </div>
                    </Link>
                </>
                : <>
                    <Link to={`/game/${Math.floor(100000 + Math.random() * 900000)}`}>
                        <div className="base_button" style={{ marginBottom: 14 }}>
                            Créer une partie
                        </div>
                    </Link>
                    <div className="base_button" onClick={() => setJoin(true)}>
                        Rejoindre une partie
                    </div>
                </>}
        </div>
    )
}
