export const questions = [
    "Dans ce groupe, qui amènerais-tu avec toi sur une île déserte ?",
    "Raconte la plus grande honte que tu as vécue ?",
    // "Quel est le rêve le plus étrange que tu aies fait ?",
    "Tu meurts dans 1 heure, tu fais quoi ?",
    "De 0 à 10. Quelle note donnerais-tu à la personne à ta droite : en beauté, sympathie ou intelligence ?",
    "De 0 à 10. Quelle note te donnerais-tu à toi-même en beauté, sympathie ou intelligence ?",
    "Quel est le pire cadeau que tu aies jamais reçu ?",
    "Quel est le meilleur cadeau qu'on t'ait jamais fait ?",
    "Qu'est-ce qui manquera jamais dans ton frigo ?",
    "Est-ce que tu pourrais coucher avec quelqu'un pour beaucoup d'argent ? Pour combien ?",
    "As-tu déjà envoyé un nude ?",
    "As-tu déjà embrassé plusieurs personnes le même jour ? Si oui, combien ?",
    "De quoi es-tu le/la plus fier.e ?",
    "Si tu pouvais ramener une personne décédée à la vie, qui serait-elle ?",
    "Si tu pouvais voyager dans le temps, à quelle époque irais-tu ?",
    "Quelle est ta technique de séduction ?",
    "Quel le plus grand mensonge que tu aies raconté ?",
    "Quelle est la pire connerie que t'aies jamais faite ?",
    "T'as déjà été bourré.e ? Si oui, raconte-nous ce qu'il s'est passé pendant ta soirée la plus alcoolisée.",
    "T'as déjà fumé une clope ?",
    "T'as déjà commis un délit ? Quoi ?",
    "T'as déjà été arrêté par la police ? Pourquoi ?",
    "Ta destination rêvée pour partir en vacances ?",
    "Quel est le membre préféré de ta famille ?",
    "Quelle est la célébrité que tu trouves le plus beau ?",
    "Quelle est la célébrité que tu trouves la plus belle ?",
    "Liste les drogues que tu as déjà prises",
    "Tu t'es déjà fait caca dessus, si oui, raconte-nous l'histoire !",
    "Avec quelle personne célèbre tu coucherais ?",
    "T'es déjà parti.e sans payer l'addition ?",
    "C'est quoi ton plat préféré ?",
    "Tu ronfles ?",
    "Est-ce que t'as une manie bizarre ?",
    "Tu ferais Koh Lanta ?",
    "Tu crois au coup de foudre ?",
    "Quelle est la pire chose qui pourrait t'arriver ?",
    "Quel est l'âge de ton premier bisous ?",
    "Si tu devais avoir un tatouage, ce serait quoi et où il serait ?",
    "Lequel des 7 péchés capitaux dirige ta vie ?",
    "Quel est ton film préféré ?",
    "À quel âge as-tu perdu ta virginité ?",
    "T'as déjà dormi dans la rue ?",
    "T'as déjà volé quelque chose, si oui, c'était quoi ?",
    "Si tu devais rencontrer une de tes idoles, morte ou vivante, qui serait-elle et pourquoi ?",
    "Qui tu mettrais sur des billets de banque ?",
    "Tu fais pipi sous la douche ?",
    "Quel est le concert auquel tu rêverais d'assister ?",
    "T'as déjà rembourré ton soutif ou ton slip ?",
    "As-tu déjà eu des relations sexuelles avec une personne de ce groupe ?",
    "As-tu déjà pensé à tromper un petit copain/petite copine ?",
    "Quelle est ta position sexuelle préférée ?",
    "As-tu déjà eu des relations sexuelles avec un inconnu/e ?",
    "As-tu déjà eu des relations sexuelles dans la rue ?",
    "As-tu déjà été surpris pendant des relations sexuelles ?",
    "As-tu déjà eu des relations sexuelles avec une personne de ton sexe ?",
    "As-tu déjà mis en doute ton orientation sexuelle ?",
    "As-tu déjà été avec quelqu'un rencontré en ligne ?",
    "T'as déjà fait un trio ?",
    "Quelle a été ta pire expérience sexuelle ?",
    "Quelle est ta plus grosse honte sexuelle ?",
    "Selon toi, a quel point le sexe est important dans un couple ? (en %)",
    "À quel âge t'as fait ta première fois ?",
    "Te considères-tu comme un bon coup ?",
    "Combien d'aventures d'un soir t'as déjà eu ?",
    "Quel est l'endroit le plus fou où tu as fait des trucs ?",
    "T'as un fétiche ? Si oui, lequel ?",
    "T'aimes les pieds ?",
    "Si tu devais te faire tatouer sur le pubis, tu ferais quoi ?",
    "Avec combien de personnes t'as déjà couché ?",
    "T'as déjà couché avec un.e ami.e ?",
    "T'as déjà fait du sexe via webcam avec ton/ta petit.e ami.e ?",
    "T'as déjà eu une MST/IST ?",
    "T'as déjà eu des rapports non protégés ?",
    "Tu aimes parler quand tu fais l'amour ?",
    "Tu es adepte du bondage ?",
    "Tu es sado, maso ou les deux ?",
    "Qu'est-ce que tu préfères dans les préliminaires ?",
    "Tu as déjà simulé un orgasme ?",
    "Quel est ton fantasme sexuel ?",
    "Tu es plutôt fesses ou seins ?",
    "Tu t'es déjà masturbé dans un endroit public ?",
    "Y'a des trucs bizarres qui t'excitent ?",
    "Qu'est-ce qui t'excite le plus ?",
    "Quelqu'un t'a déjà surpris en train de faire des trucs ?",
    "Tu as déjà vu des gens en train de faire des trucs ?",
    "Tu as déjà fait appel aux services d'un.e escorte ?",
    "Avec quelle célébrité aimerais-tu passer une nuit endiablée ?",
    "Quelle est la chose la plus bizarre que tu aies jamais mangée ? ",
    "Combien de jours as-tu déjà passé sans te laver ? ",
    "Que ferais-tu si tu recevais un chèque d’un million ? ",
    "Quel était ton surnom quand tu étais petit(e) ? ",
    "Est-ce que tu as déjà menti pour que quelqu’un t’apprécie ? ",
    "De quoi as-tu le plus peur ? ",
    "As-tu déjà triché à un examen ? ",
    "As-tu déjà embrassé une personne du même sexe ? ",
    "Quelle était la chose la plus folle que tu ai faite en étant bourré(e) ? ",
    "Est-ce que tu dors nu ou en pyjama ? ",
    "Avec quelle célébrité pourrais-tu passer une nuit ? ",
    "As-tu déjà eu un black-out à cause de l'alcool ?",
    "Est-ce que tu as déjà trahi un secret à cause de l’alcool ?",
    "As-tu déjà vomi pendant une soirée, et recommencé à boire après ?",
    "Quel âge avais-tu la première fois que tu t’es masturbé ? ",
    "Comment s’est passée ta première cuite ? Raconte ! ",
    "Est-ce que tu as toujours des sentiments pour ton ex ?",
    "Quelle a été ton expérience sexuelle la plus étrange ? ",
    "Est-ce que tu regardes des films pornos ? ",
    "Combien de temps es-tu resté le plus longtemps sans avoir de relations sexuelles ? ",
    "As-tu déjà fait des photos nu(e) ? ",
    "Est-ce que tu as déjà eu honte d’avoir embrassé, ou couché avec quelqu’un ? ",
    "As-tu déjà désiré le ou la partenaire d’un(e) pote ?",
    "As-tu déjà utilisé des sex-toys ? ",
    "Quel est le secret que tu as caché à tes parents ?",
    "Quelle est la musique la plus embarrassante que tu écoutes ?",
    "Quelle est la chose que tu aimerais pouvoir changer chez toi ?",
    "Qui est ton coup de foudre secret?",
    "Si un génie t'accordait un souhait, que demanderais-tu ?",
    "Si tu pouvais être invisible, quelle est la première chose que tu ferais ?",
    "Révéles tous les détails de ton premier baiser.",
    "Quelle excuse as-tu utilisé avant pour sortir d'un plan ?",
    "Quelle est la durée de sommeil la plus longue de ta vie ?",
    "Quand as-tu menti pour la dernière fois ?",
    "Quelle est la choses que tu apporterais sur une île déserte ?",
    "Selon toi, à quel animal ressembles-tu le plus ?",
    "Combien de selfies prends-tu par jour ?",
    "Quelle est la chose pour laquelle tu ferais la queue pendant une heure ?",
    "Combien de temps as-tu passé sans te doucher ? (maximum)",
    "Quelle est la chanson la plus écoutée sur ton téléphone ?",
    "Quelle était ton émission d’enfance préférée ?",
    "Si tu devais changer de nom, quel serait ton nouveau prénom ?",
    "Quelle est ta plus grande peur ?",
    "Quelle est la chose idiote dont tu ne peux pas te passer ?",
    "Quelle est la tendance la plus étrange à laquelle tu aies jamais participé ?",
    "À quelle personne envoyes-tu le plus de messages ?",
    "As-tu déjà été licencié d’un emploi?",
    "Parles du pire rendez-vous que tu as jamais eu.",
    "Qui a été ton premier coup de cœur pour les célébrités ?",
    "Quel est le rêve le plus étrange que tu as jamais fait ?",
    "Quelle est ta pire habitude ?",
    "Est-ce que tu fais pipi sous la douche ?",
    // "Quelle est la chose la plus aléatoire dans ton sac en ce moment ?",
    "Quel est le dernier film qui t'a fait pleurer ?",
    "Quelle est la dernière chose que tu as recherchée sur Google ?",
    "Quand as-tu été pris en flagrant délit de mensonge pour la dernière fois ?",
    "Quelle odeur bizarre apprécies-tu secrètement ?",
    "Quelle est la dernière personne à qui tu as dit “je t’aime” ?",
    "Si on te remettait 1 000 euro maintenant, comment les dépenserais-tu",
    "As-tu déjà triché à un examen ?",
    "Quelle est la bouffe/plat étrange que tu aimes ?",
    "Quelle était ta plus grande peur d’enfance ?",
    "As-tu déjà accidentellement heurté quelque chose avec ta voiture ?",
    "As-tu déjà cassé un objet coûteux ?",
    // "Qui est le meilleur professeur que tu aies jamais eu et pourquoi ?",
    "Quel est le pire aliment que tu aies jamais goûté ?",
    "As-tu déjà révélé le secret d’un ami à quelqu’un d’autre ?",
    "Quelle partie inattendue du corps trouves-tu attirante ?",
    "As-tu déjà flirté avec le frère/la soeur d’un ami proche?",
    "Si tu avais le choix de ne plus jamais dormir, le prendrais-tu ?",
    "Si tu devais te faire tatouer aujourd’hui, ce serait quoi ?",
    "Si tu pouvais voyager dans le passé et rencontrer une personne, qui serait-ce ?",
    // "Quel artiste de musique populaire détestes-tu secrètement ?",
    "Où tu vois-tu dans 10 ans ?",
    "Quelle est la chose la plus aventureuse que tu aies jamais faite ?",
    "Quel est le plus gros mensonge que tu aies jamais dit à vos parents ?",
    "Quelle est la pire douleur physique que tu aies jamais ressentie ?",
    "Quel joueur connaîs-tu le mieux ?",
    "Quelle est ta partie préférée de ton corps ?",
    // "Si tu ne pouvais accomplir que trois choses dans la vie, ça serait quoi ?",
    "Quel est ton plus grand fantasme ?",
    "Raconte-nous ton premier baiser.",
    "Quelle est la chose la plus gênante dans ta chambre ?",
    "Quelle a été la rencontre amoureuse la plus gênante que tu aies eue ?",
    "As-tu un talent caché ?",
    "Quelle est la chose la plus stupide que tu aies jamais faite ?",
    "Parles-nous de ton rendez-vous le plus gênant.",
    "Quelle est la chose la plus chère que tu aies volée ?",
    "Qu’est-ce que tu es content que ta mère ne sache pas à ton sujet ?",
    "Quelle est la pire expérience intime que tu aies jamais vécue ?",
    "Quelle est la plus grosse erreur que tu aies jamais commise ?",
    "Quelle est la chose la plus dégoûtante que tu aies jamais faite ?",
    "Quelle est la pire chose qu’on t’ait jamais faite ?",
    "Quelle est ta pire habitude ?",
    "As-tu déjà menti pour sortir d’un mauvais rendez-vous ?",
    "Ton plus long temps passé en couple ?",
    "As-tu déjà menti à quelqu'un ici ?",
    "La plus grande différence d'âge que tu as eu avec un partenaire ?",
    "La partie de ton corps que tu kiff le plus ?",
    "Avec qui de nous passerais-tu ta dernière journée sur terre ?",
    "Avec qui passerais-tu ta dernière journée sur terre ?",
    "Dominée ou être dominé ?",
    "As-tu déjà essayé l’amour avec une personne du même sexe ?",
    "As-tu déjà couché avec plus d’une personne à la fois ?",
    "Combien de personne as-tu déjà embrassé ?",
    "Quel est le rêve le plus érotique que tu aies fait ?",
    "As-tu déjà pété pendant que tu faisais l’amour ?",
    "Quelle est la chose la plus sexy chez toi ?",
    "Quelle est ta position sexuelle préférée ?",
    "Quand t’es-tu masturbé pour la dernière fois ?",
    "Combien de fois par semaine te douches-tu ?",
    "Est-ce que tu portes des sous-vêtements si vieux qu’ils ont des trous dedans ?",
    "Lequel ou laquelle d'ici a le pire goût en terme de mode ?",
    "Est-ce que tu as déjà passé la nuit en prison ?",
    "Est-ce que quelqu’un t’a déjà posé un lapin ?",
    "Est-ce que tu es déjà sorti(e) avec quelqu’un seulement pour son argent ?",
    "Qu’est-ce qui t’excite le plus ?",
    "Est-ce que tu as déjà eu une maladie sexuellement transmissible ?",
    "Est-ce que tu as déjà donné une maladie sexuellement transmissible ?",
    "Préfères-tu le sexe sensuel ou un peu plus sauvage ?",
    "Aimerais-tu tester le sexe anal ?",
    "Quel est le pire cadeau que tu as reçu dans ta vie ?",
    "Est-ce que tu dors nu.e ?",
    "Si tu pouvais sortir avec n’importe quelle personne sur la terre, qui choisirais tu ?",
    "Quel.le est ton ami.e la/le plus moche",
    "Voudrais tu aller à un rendez-vous avec quelqu’un que tu as rencontré en ligne ?",
    "Combien de temps maxi ne tu t’es pas lavé ?",
    "Quelle musique embarrassante est-ce que tu écoutes en cachette ?",
    "As-tu déjà eu un coup d’un soir ? Si oui, combien ?",
    "Est-ce que tu as déjà montré ton sexe à quelqu’un ?",
    "Si chaque personne avait un prix, le tiens serait quoi ?",
    "Quand est-ce que tu as commencé à masturber ?",
    "As-tu déjà gouté de l’urine ? Quel est le gout et comment est-ce arrivé ?",
    "Qu’est-ce qui t’excite le plus ?",
    "Quelle est la pire des chose que tu as fait à quelqu’un ?",
    "Quel est le truc le plus pervers que tu as fait ?",
    "As-tu déjà fait des trucs sales dans une voiture ?",
    "Quel est ton dessin animé préféré de ta génération ?",
    "Est-ce que tu coucherais avec quelqu’un pour monter dans l’échelle sociale ?",
    "Qui est ton/ta star du porno préféré ?",
    "As-tu déjà sucer/lécher quelqu’un du même sexe ?",
    "Quel est ton fantasme préféré pour te toucher ?",
    "Est-ce que tu t’es déjà blessé/e pendant le sexe ?",
    "Combien de fois maxi as-tu masturbé en une journée ?",
    "Préférais-tu plutôt faire l’amour sur une machine à laver ou sur la capot d’une voiture ?",
    "Est-ce que tu aimes le sexe anal ?",
    "As-tu déjà joui dans une douche partagée avec d'autres personnes",
    "Est-ce que tu as déjà espionné quelqu’un sur les réseaux ?",
    "Quel est ton talent caché ?"
];