export const whoWill = [
    "Qui est le/la plus intelligent.e ?",
    "Qui pourrait être le prochain président de la République ?",
    "Qui est le/la plus beau/belle ?",
    "Qui est le/la plus sportif/sportive ?",
    "Qui est le/la moins sportif/sportive ?",
    "Qui est le/la plus drôle ?",
    "Qui est le/la plus riche ?",
    "Qui cuisine le mieux ?",
    "Qui a le meilleur style ?",
    "Qui est le plus chiant bourré.e ?",
    "Qui sera le prochain à se marier ?",
    "Qui sera le prochain à avoir un enfant ?",
    "Qui peut vivre le plus longtemps sans se doucher ?",
    "Qui peut se retrouver au centre d'une bagarre ?",
    "Qui va devenir célèbre ?",
    "Qui va devenir millionnaire ?",
    "Qui pleure devant un film d'amour ?",
    "Qui mange la bouffe tombée par terre ?",
    "Qui va vivre 100 ans ?",
    "Qui est le plus radin ?",
    "Qui est le plus dépensié ?",
    "Qui est le plus responsable ?",
    "Qui est le plus en retard ?",
    "Qui boit le plus ?",
    "Qui ne pourrait pas garder un secret pendant plus de 30min ?",
]