import React, { useEffect, useState } from 'react'
import { Wheel } from 'react-custom-roulette'
import "./index.css";
import { data } from '../../../../RouletteData';
import { database } from '../../../../FirebaseClient';
import { ref, set } from 'firebase/database';



export default ({ infos, lobbyData }) => {

  const { prizeNumber, rouletteStarted, player: { name, id }} = infos;
  const { lobbyId, questionIndex } = lobbyData;
  const [mustSpin, setMustSpin] = useState(false);
  const [mustReveal, setMustReveal] = useState(false);

  useEffect(() => {
    if(rouletteStarted) {
      setMustSpin(true);
      setTimeout(() => {
        setMustReveal(true);
      }, 7200);
    }
  }, [lobbyData])

  const handleSpinClick = () => {
    set(ref(database, '/games/' + lobbyId + '/questions/' + questionIndex + '/rouletteStarted'), true);
  }

  return (
    <>
      <p className="roulette_title"> <span style={{ fontWeight: 'bold', color: 'white' }}> {name} </span> va tourner la roulette </p>
      <Wheel
        mustStartSpinning={mustSpin}
        perpendicularText={true}
        prizeNumber={prizeNumber}
        data={data}
        backgroundColors={['#17BEBB','#EF3E36','#EDB88B']}
        innerBorderWidth={2}
        outerBorderWidth={2}
        outerBorderColor={'white'}
        radiusLineWidth={1}
        radiusLineColor={'whitesmoke'}
        fontSize={22}
        spinDuration={0.6}
        onStopSpinning={() => {
          setMustSpin(false);
        }}
      />
      {mustReveal && <p className="roulette_title" style={{ fontWeight: 'bold', color: data[prizeNumber].style.backgroundColor}}> {data[prizeNumber].title} </p>}
      {!rouletteStarted && window.localStorage.getItem('playerInfo') == lobbyId + '-' + id && <div className="base_button roulette_button" onClick={handleSpinClick}> Faire tourner la roulette </div>}
    </>
  )
}