import React, { useEffect, useState } from 'react'
import "./index.css"
import { whoWill } from '../../../../WhoWill';
import { database } from '../../../../FirebaseClient';
import { ref, onValue, runTransaction, child, get, set } from "firebase/database"

// const users = [
//     {
//         id: 1,
//         name: 'User 1',
//         nbVotes: 3,
//     },
//     {
//         id: 2,
//         name: 'User 2',
//         nbVotes: 2,
//     },
//     {
//         id: 3,
//         name: 'User 3',
//         nbVotes: 5,
//     },
//     {
//         id: 4,
//         name: 'User 4',
//         nbVotes: 0,
//     },
// ]

export default function ({ users, infos, lobbyData }) {

    let { lobbyId, questionIndex } = lobbyData;
    const [hasVoted, setHasVoted] = useState(false);

    const createLobby = () => {
        set(ref(database, 'games/' + lobbyId + '/vote' + questionIndex), {
            players: [],
            totalVotes: 0,
        });
    }

    useEffect(() => {

        const checkIfLobby = async () => {
            const dbRef = ref(database);
            get(child(dbRef, `games/${lobbyId}/vote${questionIndex}`)).then((snapshot) => {
                if (!snapshot.exists()) {
                    createLobby();
                }
            }).catch((error) => {
                console.error(error);
            })

        }

        checkIfLobby();

    }, [])

    let isVoted = false;
    if (lobbyData['vote' + questionIndex]) {
        if (lobbyData['vote' + questionIndex].totalVotes === users.length) {
            isVoted = true;
        }
    } else return null;



    const vote = (id) => {
        const postRef = ref(database, 'games/' + lobbyId + '/vote' + questionIndex);

        runTransaction(postRef, (ve) => {
            if (ve) {
                ve.totalVotes++;
                if (ve.players) {
                    ve.players.push(id);
                } else {
                    ve.players = [id];
                }
            }
            return ve;
        });

        setHasVoted(true);
    }

    let usersSorted = users.map(u => { return { ...u, nbVotes: 0 } });

    if (isVoted) {

        for (let id of lobbyData['vote' + questionIndex].players) {
            usersSorted.find(user => user.id === id).nbVotes++;
        }

        usersSorted.sort((b, a) => a.nbVotes - b.nbVotes);
        usersSorted = usersSorted.filter(u => u.nbVotes > 0);
    }

    return (
        <div className="vote">
            <p className="vote_intitule"> {whoWill[infos.question]} </p>
            <p className="vote_subtitle"> (Clique pour voter, c'est anonyme 😉) </p>
            {hasVoted ? isVoted ?
                <div className="vote_sortedUsers">
                    {usersSorted.map(u => <p key={u.id} className="vote_sortedUser">
                        <span style={{ fontWeight: 'bold' }}> {u.name} </span> ({u.nbVotes})
                    </p>)}
                </div> :
                <p className="vote_waitingScreen">
                    En attente des votes du reste du groupe
                </p>
                : <div className="vote_users">
                    {usersSorted.map(u => <p key={u.id} onClick={() => vote(u.id)} className="vote_selectUser"> {u.name} </p>)}
                </div>}
        </div>
    )
}
