import { useEffect, useState } from "react"
import React from 'react'
import InGame from "./InGame";
import Lobby from "./Lobby";
import { useLoaderData } from "react-router-dom";
import { database } from "../../FirebaseClient";
import { ref, onValue, child, get, set  } from "firebase/database"
import { whoWill } from "../../WhoWill";
import { questions } from "../../QuestionsStore";
import { gages } from "../../Gages";
import { data } from "../../RouletteData";


//function that generate 2 int between two given numbers
function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

//on charge les infos de la bonne ville
export async function loader({ params }) {
    return params.gameId;
}


export default function Party() {

    const [ isLobby, setIsLobby ] = useState(0);
    const lobbyId = useLoaderData();
    const [ lobbyData, setLobbyData ] = useState(null);
    const [ isOwner, setIsOwner ] = useState(lobbyId === window.localStorage.getItem('owner'));

    useEffect(() => {

        const checkIfLobby = async () => {
            const dbRef = ref(database);
            get(child(dbRef, `games/${lobbyId}`)).then((snapshot) => {
                if (snapshot.exists()) {
                    setIsLobby(1);
                } else {
                    createLobby();
                    setIsLobby(1);
                }
            }).catch((error) => {
                console.error(error);
            })

        }

        checkIfLobby();

    }, []);

    useEffect(() => {
        if(isLobby === 1) {
            const dbRef = ref(database);
            onValue(child(dbRef, `games/${lobbyId}`), (snapshot) => {
                const data = snapshot.val();
                setLobbyData(data);
            });
        }
    }, [isLobby]);

    useEffect(() => {
        if(isLobby === 1 && !lobbyData?.finished && lobbyData?.questionIndex >= 0) {
            setIsLobby(2);
        }
        if(lobbyData?.finished && isLobby !== 1) {
            setIsLobby(1);
        }
    }, [lobbyData]);

    const createLobby = () => {
        window.localStorage.setItem('owner', lobbyId);
        set(ref(database, 'games/' + lobbyId), {
          players: [],
          question: 0,
        });
        setIsOwner(true);
        setIsLobby(1);
    }

    const startGame = async () => {

        const qListe = generateQuestions();

        await set(ref(database, `games/${lobbyId}`), {
            players: lobbyData.players,
            questions: qListe,
            lobbyId: lobbyId,
            questionIndex: 0,
            finished: false,
        });

        setIsLobby(2);
        
    }

    const nextQ = () => {
        let maxQuestions = lobbyData.players.length > 4 ? lobbyData.players.length * 7 : 40;
        console.log(maxQuestions, lobbyData.questionsIndex, lobbyData);
        if(lobbyData.questionIndex + 1 > maxQuestions - 1) {
            set(ref(database, `games/${lobbyId}/finished`), true);
            return;
        }
        set(ref(database, `games/${lobbyId}/questionIndex`), lobbyData.questionIndex + 1);
    }

    const generateQuestions = () => {
        let listeQuestions = [];
        let j = 0;

        let index = 0;
        let index2 = lobbyData.players.length > 2 ? 2 : 0;
        let index3 = lobbyData.players.length - 1;

        let alreadyDone = [];

        let maxQuestions = lobbyData.players.length > 4 ? lobbyData.players.length * 7 : 40;

        for(let i = 0; i < maxQuestions; i++) {
            let currentQ = {};
            if(j === 0 || j === 1 || j === 3 || j === 5 ) {
                currentQ.player = lobbyData.players[index];
                currentQ.type = 'classique';
                let random = Math.random();
                let gageF = '';
                if(random < 0.175) {
                    gageF = `Prends ${getRandomInt(3,8)} gorgées et donnes en ${getRandomInt(3,8)}`;
                } else if(random < 0.3) {
                    gageF = `Fais ${getRandomInt(5,10)} pompes et ton voisin de droite en fait ${getRandomInt(3,8)}`;
                } else if(random < 0.475) {
                    gageF = `Fais ${getRandomInt(5,10)} squat et ton voisin de droite en fait ${getRandomInt(3,8)}`;
                } else if(random < 0.55) {
                    let tempNb = getRandomInt(3,8);
                    gageF = `Prends ${tempNb} gorgées, ton voisin de droite ${tempNb - 1} et ainsi de suite`;
                } else {
                    gageF = gages[Math.floor(Math.random() * gages.length)];
                }
                currentQ.question = [Math.floor(Math.random() * questions.length), gageF];
                index++;
                j++;
            } else if (j === 4) {
                currentQ.player = lobbyData.players[index2];
                currentQ.type = 'multiples';
                let pick1, pick2, pick3;
                do {
                    pick1 = Math.floor(Math.random() * questions.length);
                    pick2 = Math.floor(Math.random() * questions.length);
                    pick3 = Math.floor(Math.random() * questions.length);
                } while(pick1 === pick2 || pick1 === pick3 || pick2 === pick3);
                currentQ.question = [ pick1, pick2, pick3 ];
                index2++;
                j++;
            } else if (j === 2) {
                currentQ.type = 'qui';
                currentQ.voted = [];
                let pick = Math.floor(Math.random() * whoWill.length);
                while(alreadyDone.includes(pick)) {
                    pick = Math.floor(Math.random() * whoWill.length);
                }
                currentQ.question = pick;
                j++;
            } else {
                currentQ.player = lobbyData.players[index3];
                const newPrizeNumber = Math.floor(Math.random() * data.length)
                currentQ.prizeNumber = newPrizeNumber;
                currentQ.rouletteStarted = false;
                currentQ.type = 'roulette';
                index3++;
                j = 0;
            }

            if(index > lobbyData.players.length - 1) {
                index = 0;
            }
            if(index2 > lobbyData.players.length - 1) {
                index2 = 0;
            }
            if(index3 > lobbyData.players.length - 1) {
                index3 = 0;
            }

            listeQuestions.push(currentQ);
        }
        
        return listeQuestions;
    }

    const addName = (nom) => {

        //add name to the localstore
        const randomId = Math.floor(Math.random() * 100000);
        window.localStorage.setItem('playerInfo', lobbyId + '-' + randomId);

        const dbRef = ref(database);
        get(child(dbRef, `games/${lobbyId}`)).then((snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                let players = data.players ?? [];
                players.push({name: nom, id: randomId});
                set(ref(database, 'games/' + lobbyId), {
                    players: players,
                });
            } else {
                console.log("No data available");
            }
        }).catch((error) => {
            console.error(error);
        });
    }

    return (
        <>
            {isLobby === 0 ? 
            <p> Loading... </p> : 
            isLobby === 1 || lobbyData?.finished ? 
            <Lobby startGame={startGame} pushName={addName} lobbyId={lobbyId} data={lobbyData} owner={isOwner} /> : 
            <InGame nextQ={nextQ} lobbyData={lobbyData} owner={isOwner} />}
        </>
    )
}
