export const gages = [
    "Prends une gorgée",
    "Prends 3 gorgées",
    "Prends 5 gorgées",
    "Fais 10 pompes",
    "Laisse ton téléphone à la personne à ta droite (30s)",
    "Lis ton dernier message instagram",
    "Lis ton dernier message messenger",
    "Lis ton dernier message whatsapp",
    "Lis ton dernier message",
    "Prends 3 gorgées",
    "Prends 5 gorgées",
    "Fais 10 squats",
    "Fais 5 squats",
    "Prends 3 gorgées et tout le monde prend une gorgée",
    "Laisse le groupe envoyer un message à ton dernier dm",
]